<template>
  <div class="col-12">
    <Breadcrumb :home="home" :model="breadcrumbitems" />
    <TabMenu :model="items" :activeIndex="activeIndex" />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbitems: [
        {
          label: 'Consignações',
          to: { name: 'consignacoes' },
        },
        { label: 'Detalhe da consignação' },
      ],
      activeIndex: 0,
      tabIndex: {
        'consignacao-detalhe': 0,
        'consignacao-parcelas': 1,
        // 'consignacao-comprovantes': 2,
        // 'consignacao-historico': 3,
      },
      items: [
        { label: 'Consignação', to: { name: 'consignacao-detalhe' } },
        {
          label: 'Parcelas',
          to: { name: 'consignacao-parcelas' },
        },
        // { label: 'Comprovantes', to: { name: 'consignacao-comprovantes' } },
        // { label: 'Histórico', to: { name: 'consignacao-historico' } },
      ],
    }
  },

  mounted() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },

  updated() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },
}
</script>

<style lang="scss" scoped>
::v-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

::v-deep .p-highlight .router-link-exact-active {
  color: var(--primary-color) !important;
}

::v-deep .p-menuitem-link {
  height: 103%;
}
</style>
